<template>
  <div class="col p-0">
    <mobile-map />
  </div>
</template>

<script>
import MobileMap from "@/components/Mobile/Maps/MobileMap";
export default {
  name: "mobile-map-page",
  components: {
    MobileMap,
  },
};
</script>

<style>
</style>